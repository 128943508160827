
import './App.css';
import Footer from './components/footer/Footer';
import HeaderTop from './components/header/HeaderTop';
import Products from './pages/Products';
import Contact from './pages/Contact';
import About from './pages/About';
import {Routes} from 'react-router-dom' ;
import {Route} from 'react-router-dom' ;
import Home from './pages/Home';
import History from './pages/History';
import Cocinas from './pages/Cocinas';
import Meriendas from './pages/Meriendas';
import Desayunos from './pages/Desayunos';
import Boutique from './pages/Boutique';
import ProductDetails from './pages/ProductDetails';

 function App() {
  return (
     <>
      <HeaderTop />
             <Routes>
               <Route  path='/' element={<Home />} />
               <Route exact path='/products' element={<Products />} />
               <Route exact path='/about' element={<About />} />
               <Route exact path='/contact' element={<Contact />} />
               <Route exact path='/historia' element={<History />} />
               <Route exact path='/cocinas' element={<Cocinas />} />
               <Route exact path='/meriendas' element={<Meriendas />} />
               <Route exact path='/desayunos' element={<Desayunos />} />
               <Route exact path='/boutique' element={<Boutique />} />
               <Route exact path='/productDetails' element={<ProductDetails />} />
             </Routes>
      <Footer />
    </>
  );
}

export default App;
