import React from 'react'
import BottomFooter from './BottomFooter';
import PreFooter from './PreFooter';




export default function Footer(){
    return(
        <>
         {/* <section className="six"> */}
          <div className="footer">
             <PreFooter />
             <BottomFooter />
          </div>
          {/* </section> */}
        </>
    );
}