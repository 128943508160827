
// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper.min.css'
import "swiper/components/pagination"
import "swiper/components/navigation"

import "../../style/StyleSwipper.css";


// import Swiper core and required modules
import SwiperCore, {
  Pagination,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination,Navigation]);


export default function SwipperMulti() {
  return (
    <section className="five" >
       <div className="section-title section" >
          <h2 className="title-product"id="instagram">BLANCAFLOR  <span>  INSTAGRAM</span></h2>
       </div>
    <section className="details-section">
                <div className="crsl">
            <Swiper slidesPerView={2} spaceBetween={5} slidesPerGroup={2} loop={true} loopFillGroupWithBlank={true} pagination={{
          "clickable": true
        }} breakpoints={{
          "300": {
            "slidesPerView": 1,
            "spaceBetween": 50
          },
          "768": {
            "slidesPerView": 4,
            "spaceBetween": 30
          },
          "1024": {
            "slidesPerView": 4,
            "spaceBetween": 10
          }
        }}
        navigation={true} className="mySwiper"   data-swiper-parallax="-23%" style={{'height': '400px'}}>
          <SwiperSlide style={{'background-image': 'url(https://unsophisticook.com/wp-content/uploads/2013/06/Salt-Dough-Recipe-1.jpg)'}}>Blancaflor Harina</SwiperSlide>
          <SwiperSlide style={{'background-image': 'url(https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/colorful-measuring-cup-on-wooden-table-royalty-free-image-1619017624.?crop=1.00xw:0.751xh;0,0.108xh&resize=1200:*)'}}>Blancaflor Harina</SwiperSlide>
          <SwiperSlide style={{'background-image': 'url(https://www.janespatisserie.com/wp-content/uploads/2021/11/gingerbreadcupcakes3-2-500x500.jpg)'}}>Blancaflor Harina</SwiperSlide>
          <SwiperSlide style={{'background-image': 'url(https://hips.hearstapps.com/ghk.h-cdn.co/assets/16/22/1464984936-waffle-collage.jpg)'}}>Blancaflor Harina</SwiperSlide>
          <SwiperSlide style={{'background-image': 'url(https://plazavea.vteximg.com.br/arquivos/reposteria-productos-preparacion.jpg)'}}>Blancaflor Harina</SwiperSlide>
          <SwiperSlide style={{'background-image': 'url(https://img-global.cpcdn.com/recipes/0fcd4b690d83e569/1200x630cq70/photo.jpg)'}}>Blancaflor Harina</SwiperSlide>
          </Swiper>
          </div>
  </section>
  </section>
  )
}