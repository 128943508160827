
// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/pagination"
import "swiper/components/navigation"
import "../../App.css";


// import Swiper core and required modules
import SwiperCore, {
  Autoplay,EffectFade,Parallax,Pagination,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay,EffectFade,Parallax,Pagination,Navigation]);


export default function SwipperFeatured() {

  return (
    
    <section className="three" >
       <div className="section-title section">
          <h2 className="title-product" id="featured">FEATURED  <span> FLOUR</span></h2>
       </div>
    <section className="featured-section" >
      <div className="crs2">
      <Swiper style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff','height':'490px'}}  speed={2000} parallax={true} pagination={{
      "clickable": true
    }} autoplay={{
      "delay": 4000,
      "disableOnInteraction": false
    }}navigation={true} loop={true} className="mySwiper" data-swiper-autoplay="100">
      <div slot="container-start"  className="parallax-bg"  data-swiper-parallax="-23%" ></div>
            <div className="slid-section">
            <SwiperSlide style={{'background-image': 'url(https://i.la-croix.com/1400x933/smart/2020/06/02/1201097114/Cuisiner-famille-moment-privilegie-porteur-demotions_0.jpg)','backgroundSize':'cover'}}>
                <div className="text-featured" data-swiper-parallax="-100">
                <div className="title-featured" data-swiper-parallax="-300">FLOR DE HARINA</div>
                <div className="subtitle-featured" data-swiper-parallax="-200">NUESTRA HISTORIA</div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus
                    felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor. </p>
                    <div className="btn-seeMore-featured btn-style"><a href=" ">view recipe</a></div>
                </div>
            </SwiperSlide>
            </div>
            <SwiperSlide style={{'background-image': 'url(https://casaterra.es/wp-content/uploads/2020/03/Harinas.jpg)','backgroundSize':'cover'}}>
                <div className="text-featured" data-swiper-parallax="-100">
                <div className="title-featured" data-swiper-parallax="-300">HARINA TODO USO</div>
                <div className="subtitle-featured" data-swiper-parallax="-200">MERIENDAS BLANCAFLOR</div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus
                    felis iaculis nec. </p>
                    <div className="btn-seeMore-featured btn-style"><a href=" ">view recipe</a></div>
                </div>
            </SwiperSlide>
            <SwiperSlide style={{'background-image': 'url(https://oesteplatense.com.ar/wp-content/uploads/2021/06/pastel-reposteria-torta.jpg)','backgroundSize':'cover'}}>
                <div className="text-featured" data-swiper-parallax="-100">
                <div className="title-featured" data-swiper-parallax="-300">BLANCAFLOR</div>
                <div className="subtitle-featured" data-swiper-parallax="-200">VISITANOS</div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus
                    felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor. </p>
                    <div className="btn-seeMore-featured"><a href=" ">view recipe</a></div>
                </div>
            </SwiperSlide>
      </Swiper>
      </div>
  </section>
  </section>
    
  )
}