
// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/pagination"
import "swiper/components/navigation"
import "../../App.css";


// import Swiper core and required modules
import SwiperCore, {
  Autoplay,EffectFade,Parallax,Pagination,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay,EffectFade,Parallax,Pagination,Navigation]);


export default function Swipper() {

  return (
    <section className="one">
  <Swiper style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}}  speed={2000} parallax={true} pagination={{
  "clickable": true
}} autoplay={{
  "delay": 4000,
  "disableOnInteraction": false
}}navigation={true} loop={true} className="mySwiper" data-swiper-autoplay="100">
  <div slot="container-start"  className="parallax-bg" style={{'background-image': 'url(https://i.ytimg.com/vi/XSl03uHJTfI/maxresdefault.jpg)','backgroundSize':'cover'}} data-swiper-parallax="-23%" ></div>
        <div className="slid-section">
        <SwiperSlide>
            <div className="text" data-swiper-parallax="-100">
            <div className="subtitle" data-swiper-parallax="-200">NUESTRA HISTORIA</div>
            <div className="title" data-swiper-parallax="-300">BLANCAFLOR</div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus
                felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor. Suspendisse in sem justo. Integer laoreet
                magna nec elit suscipit, ac laoreet nibh euismod. Aliquam hendrerit lorem at elit facilisis rutrum. </p>
            </div>
            <div className="btn-seeMore btn-style"><a href=" ">Discover More</a></div>
        </SwiperSlide>
        </div>
        <SwiperSlide>
            <div className="text" data-swiper-parallax="-100">
            <div className="subtitle" data-swiper-parallax="-200">MERIENDAS BLANCAFLOR</div>
            <div className="title" data-swiper-parallax="-300">BLANCAFLOR</div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus
                felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor. </p>
            </div>
            <div className="btn-seeMore btn-style"><a href=" ">Learn more</a></div>
        </SwiperSlide>
        <SwiperSlide>
            
            
            <div className="text" data-swiper-parallax="-100">
            <div className="subtitle" data-swiper-parallax="-200">VISITANOS</div>
            <div className="title" data-swiper-parallax="-300">BLANCAFLOR</div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus
                felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor. Suspendisse in sem justo. Integer laoreet
                magna nec elit suscipit, ac laoreet nibh euismod. Aliquam hendrerit lorem at elit facilisis rutrum. Ut at
                ullamcorper velit.</p>
            </div>
            <div className="btn-seeMore"><a href=" ">Visit Us</a></div>
        </SwiperSlide>
        
  </Swiper>
  </section>
  )
}