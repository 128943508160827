
// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper.min.css'
import "swiper/components/pagination"
import "swiper/components/navigation"

import "../../style/StyleSwipper.css";


// import Swiper core and required modules
import SwiperCore, {
  Pagination,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination,Navigation]);


export default function SwipperProduct() {

  return (
    <>
     <section className="two" >
       <div className="section-title section" >
          <h2 className="title-product" id="product">OUR  <span> PRODUCTS</span></h2>
       </div>
              <div className="">
              <Swiper slidesPerView={4} spaceBetween={5} slidesPerGroup={4} loop={true} loopFillGroupWithBlank={true} pagination={{
            "clickable": true
          }} breakpoints={{
              "300": {
                "slidesPerView": 1,
                "spaceBetween": 50
              },
              "768": {
                "slidesPerView": 4,
                "spaceBetween": 30
              },
              "1024": {
                "slidesPerView": 4,
                "spaceBetween": 10
              }
            }} navigation={true} className="mySwiper"   data-swiper-parallax="-23%" style={{'height': '350px'}} 
          >
            <SwiperSlide style={{'background-image': 'url(https://wongfood.vteximg.com.br/arquivos/ids/290634-1000-1000/3269-1.jpg?v=636921693341270000)','marginTop':'-55px','backgroundSize':'contain','backgroundRepeat':'no-repeat'}}><div className="Product-title">HARINA TODO USO </div></SwiperSlide>
            <SwiperSlide style={{'background-image': 'url(https://wongfood.vteximg.com.br/arquivos/ids/290639-1000-1000/719393-1.jpg?v=636921696479030000)','marginTop':'-55px','backgroundSize':'contain','backgroundRepeat':'no-repeat'}}><div className="Product-title">FLOR DE HARINA </div></SwiperSlide>
            <SwiperSlide style={{'background-image': 'url(https://wongfood.vteximg.com.br/arquivos/ids/290634-1000-1000/3269-1.jpg?v=636921693341270000)','marginTop':'-55px','backgroundSize':'contain','backgroundRepeat':'no-repeat'}}><div className="Product-title">HARINA TODO USO</div></SwiperSlide>
            <SwiperSlide style={{'background-image': 'url(https://wongfood.vteximg.com.br/arquivos/ids/290639-1000-1000/719393-1.jpg?v=636921696479030000)','marginTop':'-55px','backgroundSize':'contain','backgroundRepeat':'no-repeat'}}><div className="Product-title">FLOR DE HARINA </div></SwiperSlide>
            <SwiperSlide style={{'background-image': 'url(https://wongfood.vteximg.com.br/arquivos/ids/290634-1000-1000/3269-1.jpg?v=636921693341270000)','marginTop':'-55px','backgroundSize':'contain','backgroundRepeat':'no-repeat'}}><div className="Product-title">HARINA TODO USO</div></SwiperSlide>
            <SwiperSlide style={{'background-image': 'url(https://wongfood.vteximg.com.br/arquivos/ids/290639-1000-1000/719393-1.jpg?v=636921696479030000)','marginTop':'-55px','backgroundSize':'contain','backgroundRepeat':'no-repeat'}}><div className="Product-title">FLOR DE HARINA</div></SwiperSlide>
            <SwiperSlide style={{'background-image': 'url(https://wongfood.vteximg.com.br/arquivos/ids/290634-1000-1000/3269-1.jpg?v=636921693341270000)','marginTop':'-55px','backgroundSize':'contain','backgroundRepeat':'no-repeat'}}><div className="Product-title">HARINA TODO USO</div></SwiperSlide>
            <SwiperSlide style={{'background-image': 'url(https://wongfood.vteximg.com.br/arquivos/ids/290639-1000-1000/719393-1.jpg?v=636921696479030000)','marginTop':'-55px','backgroundSize':'contain','backgroundRepeat':'no-repeat'}}><div className="Product-title">FLOR DE HARINA</div></SwiperSlide>
            </Swiper>

          
            </div>
            <div className="btn-section">
                <a href=" " className="">VIEWS ALL PRODUCTS</a>
            </div>
  </section>
    </>
  )
}