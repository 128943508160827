import React, { useState } from "react";
import logo from '../../images/martini-logo.png';
import {FaBars , FaTimes } from 'react-icons/fa';
import { Link } from "react-router-dom";



export default function HeaderTop(){
    const [click, setClick] = useState();
    const clickHandler = () =>{setClick(!click);};
    return(
        <>
          <div className="header-top">
             <ul  className={click ? "header-menu-1 active" : "header-menu-1"}>
                <li><Link to="/products">Productos</Link></li>
                <li><Link to="/meriendas">Meriendas Blancaflor</Link></li>
                <li><Link to="/desayunos">Desayunos Blancaflor</Link></li>
             </ul>
             <span className="header-logo"><Link to="/"><img src={logo} alt="logo"/></Link></span>
             <ul className={click ? "header-menu-2 active" : "header-menu-2"}>
                <li><Link to="/historia">Nuestra Historia</Link></li>
                <li><Link to="/cocinas">Nuestra Cocinas</Link></li>
                <li><Link to="/boutique">Boutique</Link></li>
             </ul>
             <div>
             <Link className="visit-btn" to="/contact">=Visitanos=</Link>
             </div>
             <div onClick={clickHandler} className="hamburger">{click ? <FaTimes />  : <FaBars />}</div>
          </div>
        </>
    );
}