import React from 'react' ;
import img1 from '../images/bg-historia.jpg';
import img2 from '../images/historia1.png';
import img3 from '../images/historia3.png';
import img4 from '../images/maxresdefault.jpg';





export default function History() {

    return(

        <>
         <div className="pre-header-page"></div>
         <div className="header-page page-histoia">
           <span className="header-page-title">NUESTRA HISTORIA</span>

         </div>
        
          <div className="section-title section">
          <h2 className="title-product" id="explore">NUESTRA  <span> HISTORIA</span></h2>
          </div>

          <div className='history-container'>
          <div className='history-card'>
         
         <section className='history-infos'>
           <h2 className="history-date">1927</h2>
           <div className="history-infos">Blancaflor es la marca pionera que creó la famosa harina leudante aportándole practicidad al amasado casero. Con una trayectoria de más de 60 años, sigue siendo la aliada indispensable de quienes disfrutan de amasar con un producto único.</div>
         </section>
         <section className='history-img'>
           <img className="history-card-img" src={img1} alt="history"/>
         </section>
        </div>
        <div className='history-card'>
         
          <section className='history-infos'>
            <h2 className="history-date">1965 </h2>
            <div className="history-infos">Blancaflor es la marca pionera que creó la famosa harina leudante aportándole practicidad al amasado casero. Con una trayectoria de más de 60 años, sigue siendo la aliada indispensable de quienes disfrutan de amasar con un producto único.</div>
          </section>
          <section className='history-img'>
            <img className="history-card-img" src={img3} alt="history"/>
          </section>
         </div>
         <div className='history-card'>
         
          <section className='history-infos'>
            <h2 className="history-date"> 19..</h2>
            <div className="history-infos">Blancaflor es la marca pionera que creó la famosa harina leudante aportándole practicidad al amasado casero. Con una trayectoria de más de 60 años, sigue siendo la aliada indispensable de quienes disfrutan de amasar con un producto único.</div>
          </section>
          <section className='history-img'>
            <img className="history-card-img" src={img2} alt="history"/>
          </section>
         </div>
         <div className='history-card'>
         
          <section className='history-infos'>
            <h2 className="history-date">2022</h2>
            <div className="history-infos">Blancaflor es la marca pionera que creó la famosa harina leudante aportándole practicidad al amasado casero. Con una trayectoria de más de 60 años, sigue siendo la aliada indispensable de quienes disfrutan de amasar con un producto único.</div>
          </section>
          <section className='history-img'>
            <img className="history-card-img" src={img4} alt="history"/>
          </section>
         </div>

          </div>
         
         

        </>
    );
};

