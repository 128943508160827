import React  from "react";



export default function BottomFooter(){

    return(
        <>
          <div className="bottom-footer">
              <ul>
                  <li><a href=" ">TERMS & CONDITIONS</a></li>
                  <li><a href=" ">PRIVACY POLICY</a></li>
                  <li><a href=" ">COOKIE POLICY</a></li>
                  <li><a href=" ">CONTACT US</a></li>
                  <li><a href=" ">COOKIE POLICY</a></li>
              </ul>

              <div className="footer-responsibly">
                 <h4>ENJOY BLANCAFLOR RESPONSIBLY.</h4>
              </div>
              <div className="footer-responsibly-elem">
                  <p>© 2021 BLANCAFLOR, ITS TRADE DRESS AND THE BALL & BAR LOGO ARE TRADEMARKS.</p>
              </div>
              <div className="footer-responsibly-elem">
                <p>Apoyamos la toma de decisiones responsable. Visiteresponsability.org o drinkaware.co.uk.</p>
              </div>

          </div>
        </>
    );
}