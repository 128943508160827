import React from 'react' ;



export default function Boutique() {

    return(

        <>
         <div className="pre-header-page"></div>
         <div className="header-page page-boutique">
           <span className="header-page-title">BOUTIQUE</span>
         </div>
          <h1> BOUTIQUE </h1>
        </>
    );
};

