import React from 'react' ;
import {FiPhoneCall} from 'react-icons/fi' ;
import {SiGooglemaps} from 'react-icons/si';
import {MdEmail} from 'react-icons/md' ;
// import scrol from '../images/scroldown.gif';
export default function Contact() {

    return(

        <>
        <div className="pre-header-page"></div>
         <div className="header-page">
           <span className="header-page-title">VISITANOS</span>
           {/* <a href="#contact" ><img src={scrol} alt=" " /></a> */}
           {/* <h6>DESPLAZA PARA EXPLORAR</h6> */}
         </div>


         <div className="contact-section" id="contact">
         <section className="contact-infos">
          <div className='contact-icon'>
            <span><FiPhoneCall /></span><a href="tel:+212536608822" >+212 536 608 822</a>
          </div>
          <div className='contact-icon'>
            <span><SiGooglemaps /></span><a href="https://www.google.com/maps/@35.2882778,-2.9385185,15z" >RUTA DE MELILLA N1, CAJA POSTAL 101, CODIGO POSTAL 62050 , <br></br> BENI ENSAR, MARUECOS</a>
          </div>
          <div className='contact-icon'>
            <span><MdEmail /></span><a href="mailto:correo@blancaflor.ma" >correo@blancaflor.ma</a>
          </div>
         </section>
         <section className="contact-form">
           <h2>Contactez-nous</h2>
           <form>
             <input type="text" placeholder='Nom & Prénom' />
             <input type="text" placeholder='Email' />
             <input type="text" placeholder='Telephone' />
             <input type="text" name="message" placeholder='message' style={{'width':'300px'}} />
             <button>ENVOYER</button>
           </form>
         </section>
         </div>

         <div className="map-section">
         <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d26054.927443823566!2d-2.9415225429609553!3d35.28445946829352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sma!4v1639649133539!5m2!1sfr!2sma" allowfullscreen="" loading="lazy"></iframe>
         </div>

          
        </>
    );
};

