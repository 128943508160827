import React from 'react' ;
// import bgp from '../images/bg-product.png';





export default function Desayunos() {

    return(

        <>
         <div className="pre-header-page"></div>
         <div className="header-page desayunos-page">
           <span className="header-page-title">BLANCAFLOR DESAYUNOS</span>
           {/* <h4>SCROLL TO EXPLORE</h4> */}
         </div>
          <h1> BLANCAFLOR DESAYUNOS </h1>
        </>
    );
};

