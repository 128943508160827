import React from 'react' ;
import product1 from '../images/product.jpg';
import story from '../images/our-story.jpg';


export default function Product(){

    return(
        <section className="four" >
       <div className="section-title section">
          <h2 className="title-product" id="explore">EXPLORE  <span> BLANCAFLOR</span></h2>
       </div>
        <div className="product-container">
           <section className="card" >
             <div className="image-card">
                 <img src={story} alt="product" />
             </div>
             <div className="product-details btn-style">
             <h2>NUESTRA HISTORIA</h2>
             <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.</p>
             <div>
                 <button className="btn-click btn">VISIT US</button>
             </div>
             </div>
           </section>
           <section className="card">
             <div className="image-card">
                 <img src={product1} alt="product" />
             </div>
             <div className="product-details btn-style">
             <h2>CASA BLANCAFLOR</h2>
             <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.</p>
             <div>
                 <button className="btn-click btn">VISIT US</button>
             </div>
             </div>
           </section>
           </div>
           </section>
    );
}