import React from 'react' ;
import Product from '../components/Product';
import Swipper from '../components/slide/Swipper';
import SwipperFeatured from '../components/slide/SwipperFeatured';
import SwipperMulti from '../components/slide/SwipperMulti';
import SwipperProduct from '../components/slide/SwipperProduct';


export default function Home(){

    return(
        <>
            <Swipper />
            <SwipperProduct />
            <SwipperFeatured />
            <Product />
            <SwipperMulti />
        </>
    );
}


