import React from 'react' ;
// import bgp from '../images/bg-product.png';





export default function Cocinas() {

    return(

        <>
         <div className="pre-header-page"></div>
         <div className="header-page page-cocinas">
           <span className="header-page-title">NUESTRA COCINAS</span>
         </div>
          <h1> NUESTRA COCINAS </h1>
        </>
    );
};

