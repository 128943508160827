import React from 'react' ;
import product1 from '../images/bg-about.jpg';
import product2 from '../images/bg-about.jpg';

export default function ProductDetails() {

    return(

        <>
          {/* <HeaderPage /> */}
          <div className="pre-header-page"></div>
          <div className="header-page product-page" >
           <span className="header-page-title">OUR PRODUCT</span>
           {/* <h4>SCROLL TO EXPLORE</h4> */}
         </div>
          <div>
          <div className="section-title section">
          <h2 className="title-product" id="explore">OUR  <span> PRODUCT</span></h2>
          </div>
          <div className="product-container">
           {/* <section className="card" style={{'margin':'30px','width':'400px'}}>
             <div className="image-card">
                 <img src={product2} alt="product" />
             </div>
             <div className="product-details btn-style">
             <h2>LA CLASSICA</h2>
             <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.</p>
             <div>
                 <button className="btn-click btn">BUY NOW</button>
             </div>
             </div>
           </section>
           <section className="card" style={{'margin':'30px','width':'400px'}}>
             <div className="image-card">
                 <img src={product1} alt="product" />
             </div>
             <div className="product-details btn-style">
             <h2>LA PASTELERA</h2>
             <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.</p>
             <div>
                 <button className="btn-click btn">BUY NOW</button>
             </div>
             </div>
           </section> */}
           <section className="card" style={{'margin':'30px'}}>
             <div className="image-card">
                 <img src={product1} alt="product" />
             </div>
             <div className="product-details btn-style">
             <h2>LA CLASICA : 25 / 10 / 5 / 2 / 1 kg</h2>
             <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.</p>
             <div>
                 <button className="btn-click btn">BUY NOW</button>
             </div>
             </div>
           </section>
            <section className="card" style={{'margin':'30px'}}>
             <div className="image-card">
                 <img src={product1} alt="product" />
             </div>
             <div className="product-details btn-style">
             <h2>LA PASTELERA 10 / 5 / 2 / 1 kg</h2>
             <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.</p>
             <div>
                 <button className="btn-click btn">BUY NOW</button>
             </div>
             </div>
           </section>
           </div>
          </div>
        </>
    );
};

