import React from 'react' ;
import {TiSocialFacebookCircular} from 'react-icons/ti';
import {TiSocialInstagramCircular} from 'react-icons/ti';
import {TiSocialTwitterCircular} from 'react-icons/ti';
import {TiSocialYoutubeCircular} from 'react-icons/ti';


export default function PreFooter(){

    return(
        <>
           <div className="pre-footer">
            <div className="row">
               <div className="col-sm-4">
                   <div className="footer-sing">
                       <div className="footer-title">
                       <p>MÁS INFORMACIÓN SOBRE</p>
                       </div>
                           <a href=" "> INSCRIBIRSE </a>
                   </div>

               </div>
               <div className="col-sm-4">
               <div className="footer-sing">
                          <div className="footer-title">
                          <p>SIGUE A BLANCAFLOR EN</p>
                          </div>
                           <ul>
                               <li><TiSocialFacebookCircular /></li>
                               <li><TiSocialInstagramCircular /></li>
                               <li><TiSocialTwitterCircular /></li>
                               <li><TiSocialYoutubeCircular /></li>

                           </ul>
                   </div>
               </div>
               <div className="col-sm-4">
               <div className="footer-sing">
                   <div className="footer-title">
                   <p>NUESTRA COMUNIDAD</p>
                   </div>
                           
                           <a href=" "> ESTAR INVOLUCRADO </a>
                   </div>
               </div>
               </div>
           </div>

        </>

    );
}