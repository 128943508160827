import React from 'react' ;



export default function Meriendas() {

    return(

        <>
         <div className="pre-header-page"></div>
         <div className="header-page meriendas-page">
           <span className="header-page-title">BLANCAFLOR MERIENDAS</span>
           {/* <h4>SCROLL TO EXPLORE</h4> */}
         </div>
          <h1> ABOUT PAGE </h1>
        </>
    );
};

